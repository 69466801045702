<template>
  <div>
    <v-card class="px-2">
      <v-card-title>
        <span class="headline">
          Mensajes enviados a {{ item.userName }} por orden
          {{ item.shortId }}</span
        >

        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-data-table
                :headers="headers"
                :items="item.sentMessages"
                :items-per-page="15"
                sort-by="date"
                :sort-desc="true"
                :footer-props="{
                  itemsPerPageOptions: [10, 25, 50, 100, 250],
                }"
              >
                <template v-slot:[`item.date`]="{ item }">
                  <span class>{{ item.date | filterDate }}</span>
                </template>

                <template v-slot:[`item.template`]="{ item }">
                  <span class>{{ item.template | filterTemplate }}</span>
                </template>
              </v-data-table>
            </v-col>
            <!-- <v-col cols="12" sm="12" md="12">
              <v-btn class="mt-4" color="primary"> Cerrar </v-btn>
            </v-col> -->
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
export default {
  name: "view-messagess",
  //   components: {
  //     lottie: Lottie,
  //   },
  props: ["item"],
  data() {
    return {
      headers: [
        {
          text: "Tipo de mensaje",
          value: "template",
          sortable: false,
        },
        {
          text: "Usuario que envió el mensaje",
          value: "sentBy.name",
          sortable: false,
        },
        {
          text: "Fecha",
          value: "date",
          sortable: false,
        },
      ],
    };
  },

  filters: {
    filterDate: function (timestamp) {
      if (!timestamp) return "n/a";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("DD/MM/YYYY, HH:mm:ss");
    },

    filterTemplate(value) {
      let templates = {
        business_closed_credit_card_reversion_es:
          "Comercio cerrado, reversión de cobro",
        product_delivery_es: "Chavo afuera con pedido",
        product_unavailable_es: "Producto agotado",
        general_inquiry_es: "General",
        fixed_service_survey_es: "Encuesta de servicio bienvenida",
      };
      return templates[value];
    },
  },
};
</script>
