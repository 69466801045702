<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <!-- <v-overlay class="loading-center" :value="loading">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay> -->

    <v-row class="pa-0 ma-0">
      <v-col cols="12" md="8">
        <h1 class="mb-1">Mensajería</h1>
        <small class="ml-5"> Saldo actual: $ {{ balance.toFixed(2) }} </small>
      </v-col>
      <v-col cols="12" md="4">
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value="date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="date"
              label="Filtrar por fecha"
              prepend-icon="fa-calendar-alt"
              readonly
              filled
              rounded
              hide-details
              :disabled="loading"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            range
            :title-date-format="title"
            scrollable
            :max="today"
          >
            <v-row no-gutters style="flex-wrap: nowrap">
              <v-btn
                class="flex-grow-1 flex-shrink-0"
                large
                rounded
                outlined
                color="primary"
                @click="modal = false"
                >Cancelar</v-btn
              >
              <v-btn
                class="flex-grow-1 flex-shrink-0"
                large
                rounded
                color="primary"
                @click="
                  $refs.dialog.save(date);
                  updateDateFilter();
                "
              >
                Filtrar
              </v-btn>
            </v-row>
          </v-date-picker>
        </v-dialog>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12">
        <v-row justify="end">
          <v-btn
            color="primary"
            class="ma-2 white--text"
            :loading="loading"
            :disabled="balance <= 0"
            @click="newMessage()"
          >
            <v-icon right dark class="mr-3">fas fa-plus</v-icon>
            Nuevo Mensaje
          </v-btn>
        </v-row>
      </v-col>

      <v-col cols="12" sm="12" md="12">
        <v-data-table
          :headers="headers"
          :items="messaging"
          :search="$store.state.search"
          :items-per-page="15"
          :loading="loading"
          sort-by="progressCreatedAt"
          class="mt-5"
          sort-desc
          item-key=".key"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:loading>
            <div class="ma-5" style="position: relative">
              <div
                style="
                  position: absolute;
                  z-index: 999;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p
                  :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader
                ref="skeleton"
                type="table-tbody"
                class="mx-auto d-none d-md-block"
              >
              </v-skeleton-loader>
            </div>
          </template>
          <template v-slot:[`item.progressCreatedAt`]="{ item }">
            <span class>{{ item.progressCreatedAt | filterDate }}</span>
          </template>
          <template v-slot:[`item.sentMessages`]="{ item }">
            <span class>{{
              item.sentMessages && item.sentMessages.length
                ? item.sentMessages.length
                : 0
            }}</span>
          </template>
          <template v-slot:[`item.shortId`]="{ item }">
            <v-tooltip v-if="item.shortId" right>
              <template v-slot:activator="{ on }">
                <v-chip
                  :color="
                    $vuetify.theme.dark
                      ? 'rgba(0, 0, 0, 0.4)'
                      : 'rgba(0, 0, 0, 0.1)'
                  "
                  style="display: inline-block"
                  v-on="on"
                  dark
                  :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                  @click="copyToClipboard(item.shortId)"
                >
                  {{ item.shortId }}
                </v-chip>
              </template>
              <i class="far fa-copy mr-2"></i>
              <span>Click para copiar</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.options`]="{ item }">
            <v-btn @click="showDialog(item)" small color="primary">
              Ver mensajes
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog persistent v-model="addModal" max-width="600px">
      <add-message
        @cancel="addModal = false"
        @success="handleSuccess"
        :key="randomKey"
      ></add-message>
    </v-dialog>

    <v-dialog
      persistent
      v-model="viewDialog"
      v-if="viewDialog"
      max-width="900px"
    >
      <view-message
        :item="selectedItem"
        @cancel="viewDialog = false"
      ></view-message>
    </v-dialog>
  </v-container>
</template>

<script>
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import axios from "axios";
import AddMessage from "./AddMessage";
import { db } from "@/firebase";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import viewMessage from "./viewMessage";

export default {
  name: "messages",
  components: {
    lottie: Lottie,
    AddMessage,
    viewMessage,
  },
  mixins: [lottieSettings],
  data() {
    return {
      loading: true,
      balance: 0,
      randomKey: 0,
      modal: false,
      date: [
        `${moment()
          .tz("America/Tegucigalpa")
          .subtract(6, "hours")
          .toISOString()
          .substr(0, 10)}`,
        `${moment()
          .tz("America/Tegucigalpa")
          .subtract(6, "hours")
          .toISOString()
          .substr(0, 10)}`,
      ],
      snackbar: false,
      snackbarText: "",
      addModal: false,
      viewDialog: false,
      selectedItem: null,
      messaging: [],
      headers: [
        {
          text: "ID de la orden",
          value: "shortId",
          sortable: false,
          width: "200px",
        },
        {
          text: "Nombre del cliente",
          value: "userName",
          class: "nowrap",
          width: "200px",
        },

        {
          text: "Nombre del comercio",
          value: "businessName",
          class: "nowrap",
          width: "250px",
        },
        {
          text: "Mensajes enviados",
          value: "sentMessages",
          class: "nowrap",
        },

        {
          text: "Fecha de creación de la orden",
          value: "progressCreatedAt",
          class: "nowrap",
        },

        {
          value: "options",
          sortable: false,
          align: "end",
        },
      ],
    };
  },
  filters: {
    filterDate: function (timestamp) {
      if (!timestamp) return "n/a";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("DD/MM/YYYY, HH:mm:ss");
    },
  },
  computed: {
    today() {
      return new Date().toISOString().substr(0, 10);
    },
  },

  async mounted() {
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;
    this.$store.commit("setSearchTerm", "mensajes");
    this.getCurrentBalance();

    this.getMessages();
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },

    async getMessages() {
      if (this.date[0] && this.date[1]) {
        this.loading = true;
        await this.$binding(
          "messaging",
          db
            .collection("messaging")
            .where(
              "progressCreatedAt",
              ">",
              moment(this.date[0], "YYYY-MM-DD").startOf("day").toDate()
            )
            .where(
              "progressCreatedAt",
              "<",
              moment(this.date[1], "YYYY-MM-DD").endOf("day").toDate()
            )
        );

        this.loading = false;
      }
    },

    updateDateFilter() {
      if (this.date.length > 1) {
        if (moment(this.date[0], "YYYY-MM-DD") > new Date(this.date[1])) {
          this.date = [this.date[1], this.date[0]];
        }
      } else {
        this.date = [this.date[0], this.date[0]];
      }

      this.getMessages();
    },

    showDialog(item) {
      this.selectedItem = item;
      this.viewDialog = true;
    },
    getCurrentBalance() {
      axios
        .get("https://rest.messagebird.com/balance", {
          headers: {
            Authorization: "AccessKey " + "chzLLbR4TevKCpnrEtpzThulD",
          },
        })
        .then((response) => {
          this.balance = response.data.amount;
        })
        .catch((error) => {
          console.log("error :>> ", error);
        });
    },
    newMessage() {
      this.randomKey = Math.random();
      this.addModal = true;
    },
    handleSuccess(data) {
      this.snackbarText = data;
      this.snackbar = true;
      this.addModal = false;
    },
    title() {
      return "Selecciona las fechas a filtrar";
    },
  },
};
</script>
