var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-snackbar',{attrs:{"top":"","timeout":3000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")]),_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h1',{staticClass:"mb-1"},[_vm._v("Mensajería")]),_c('small',{staticClass:"ml-5"},[_vm._v(" Saldo actual: $ "+_vm._s(_vm.balance.toFixed(2))+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.date,"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({attrs:{"label":"Filtrar por fecha","prepend-icon":"fa-calendar-alt","readonly":"","filled":"","rounded":"","hide-details":"","disabled":_vm.loading},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"range":"","title-date-format":_vm.title,"scrollable":"","max":_vm.today},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-row',{staticStyle:{"flex-wrap":"nowrap"},attrs:{"no-gutters":""}},[_c('v-btn',{staticClass:"flex-grow-1 flex-shrink-0",attrs:{"large":"","rounded":"","outlined":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v("Cancelar")]),_c('v-btn',{staticClass:"flex-grow-1 flex-shrink-0",attrs:{"large":"","rounded":"","color":"primary"},on:{"click":function($event){_vm.$refs.dialog.save(_vm.date);
                _vm.updateDateFilter();}}},[_vm._v(" Filtrar ")])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"primary","loading":_vm.loading,"disabled":_vm.balance <= 0},on:{"click":function($event){return _vm.newMessage()}}},[_c('v-icon',{staticClass:"mr-3",attrs:{"right":"","dark":""}},[_vm._v("fas fa-plus")]),_vm._v(" Nuevo Mensaje ")],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-data-table',{staticClass:"mt-5",attrs:{"headers":_vm.headers,"items":_vm.messaging,"search":_vm.$store.state.search,"items-per-page":15,"loading":_vm.loading,"sort-by":"progressCreatedAt","sort-desc":"","item-key":".key","footer-props":{
          itemsPerPageOptions: [10, 25, 50, 100, 250],
        }},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('div',{staticClass:"ma-5",staticStyle:{"position":"relative"}},[_c('div',{staticStyle:{"position":"absolute","z-index":"999","width":"100%","height":"100%","display":"flex","flex-direction":"column","justify-content":"center","align-items":"center"}},[_c('lottie',{attrs:{"options":_vm.defaultOptions,"height":300,"width":400},on:{"animCreated":_vm.handleAnimation}}),_c('p',{class:_vm.$vuetify.theme.dark
                    ? 'subtitle-1 primary--text'
                    : 'subtitle-1 black--text'},[_vm._v(" Cargando Data ")])],1),_c('v-skeleton-loader',{ref:"skeleton",staticClass:"mx-auto d-none d-md-block",attrs:{"type":"table-tbody"}})],1)]},proxy:true},{key:`item.progressCreatedAt`,fn:function({ item }){return [_c('span',{},[_vm._v(_vm._s(_vm._f("filterDate")(item.progressCreatedAt)))])]}},{key:`item.sentMessages`,fn:function({ item }){return [_c('span',{},[_vm._v(_vm._s(item.sentMessages && item.sentMessages.length ? item.sentMessages.length : 0))])]}},{key:`item.shortId`,fn:function({ item }){return [(item.shortId)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-chip',_vm._g({staticStyle:{"display":"inline-block"},attrs:{"color":_vm.$vuetify.theme.dark
                    ? 'rgba(0, 0, 0, 0.4)'
                    : 'rgba(0, 0, 0, 0.1)',"dark":"","text-color":_vm.$vuetify.theme.dark ? 'white' : 'black'},on:{"click":function($event){return _vm.copyToClipboard(item.shortId)}}},on),[_vm._v(" "+_vm._s(item.shortId)+" ")])]}}],null,true)},[_c('i',{staticClass:"far fa-copy mr-2"}),_c('span',[_vm._v("Click para copiar")])]):_vm._e()]}},{key:`item.options`,fn:function({ item }){return [_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.showDialog(item)}}},[_vm._v(" Ver mensajes ")])]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.addModal),callback:function ($$v) {_vm.addModal=$$v},expression:"addModal"}},[_c('add-message',{key:_vm.randomKey,on:{"cancel":function($event){_vm.addModal = false},"success":_vm.handleSuccess}})],1),(_vm.viewDialog)?_c('v-dialog',{attrs:{"persistent":"","max-width":"900px"},model:{value:(_vm.viewDialog),callback:function ($$v) {_vm.viewDialog=$$v},expression:"viewDialog"}},[_c('view-message',{attrs:{"item":_vm.selectedItem},on:{"cancel":function($event){_vm.viewDialog = false}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }